$(function () {
    /*scope variables*/
    var delaySearch;
    var query = "";
    var autocompleteList = $(".js-autocompleteList");

    /*Autocomplete function*/
    $(".js-searchInputHero").keyup(function (e) {
        if (e.keyCode !== 40) {
            clearTimeout(delaySearch);
            var that = $(this);
            //Short delay not to flood Api
            delaySearch = setTimeout(function() {
                query = that.val();
                getAutocompleteResults("/Api/AutoComplete", query);
            }, 150);
        } else {
            var allChildren = $(autocompleteList).children();

            switch (e.keyCode) {
                case 40:
                    if (allChildren.length > 0) {
                        scrollResults();
                    }
                    break;
                default:
                    allChildren.find('a').removeAttr("keypress");
                    break;
            }
        }

    });

    function getAutocompleteResults(url, q) {
        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'JSON',
            data: {
                "q": q
            },
            success: success
        });
    }
    function success(data) {
        /*Empty the autocomplete list and add new results to the list*/
        var autolist = "";
        $.each(data.Hits, function (k, v) {
            autolist += "<li class='autocomplete__results-listitems'> <a href='" + data.SearchUrl + "?q=" + v + "' tabindex='0'>" + v + "</a></li>";
        });
        autocompleteList.html(autolist);
    }

    //Select autocomplete results white arrowup and arrowdown
    function scrollResults() {

        /*function scope variables*/
        var allChildren = $(autocompleteList).children();
        /*Holds the index for the selected item*/
        var index = 0;
        /*Removes previous keypress from all elements inn list*/
        allChildren.find('a').removeAttr("keypress");
        /*Sets focus on first element in the autocomplete list*/
        allChildren.first().find('a').focus();
        /*Sets the searchtext to be like the focused element*/
        $(".js-searchInputHero").val(allChildren.first().find('a')[0].outerText);
        /*Add keypress to all a elements in autocomplete list*/
        allChildren.find('a').keydown(function (e) {
            var t = $(this);
            var listelements = t.parent().parent().children();
            switch (e.keyCode) {
                /*Arrowup function keyCode 38*/
                case 38:
                    index--;
                    index < 0 ? index = listelements.length-1 : index = index;
                    $(listelements[index]).find('a').focus();
                    $(".js-searchInputHero").val($(listelements[index]).find('a')[0].outerText);
                    return false;
                /*Arrowdown function keyCode 40*/
                case 40:
                    index++;
                    index >= listelements.length ? index = 0 : index = index;
                    $(listelements[index]).find('a').focus();
                    $(".js-searchInputHero").val($(listelements[index]).find('a')[0].outerText);

                    return false;
                case 13:
                    /*On enterclick activate the link focused in the autocomplete list and do search*/
                    return true;
                default:
                    return false;
            }
        });
    }


    /*Close the autocomplete on documentclick*/
    $('.js-autocompleteList').click(function(e) {
        e.stopPropagation();
    });
    $('.js-searchInputHero').click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.js-autocompleteList').show();
        $(document).one('click', function() {
            $('.js-autocompleteList').hide();
        });
    });

});