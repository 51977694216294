$(function () {
    var ismenuopen = false;
    var menuHamburger = $(".js-hamburgercontent");
    var removeItem = $(".js-remove-icon");
    var $menuButton = $(".js-toggleMenu");
    var $menuContent = $(".js-mainmenu");

    $menuContent.click(function(e) {
        e.stopPropagation();
    });

    $menuButton.click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(document).one('click', function (e) {
            if (ismenuopen) {
                closeMenu();
                $menuContent.toggle();
            }
        });
        if (!ismenuopen) {
            openMenu();
        } else {
            closeMenu();
        }
        $menuContent.toggle();
    });

    function closeMenu() {
        removeItem.hide();
        menuHamburger.show();
        ismenuopen = false;
    }
    function openMenu() {
        menuHamburger.hide();
        removeItem.show();
        ismenuopen = true;
    }
});